import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { StandardProposalConstant } from "./StandardProposalConstant";
import { INTEGER } from "presentation/utils/numberUtil";

const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF: any[] = [
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.SEQ,
        field: 'seq',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 120,
        dataType: INTEGER,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.TARIFF_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.TARIFF_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.FORWARDER_CODE,
        field: 'forwarderCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.MASTER,
        field: 'master',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.TARIFF_CODE_DESC,
        field: 'tariffCodeDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 420,
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}
