import { StdProposalIsEntity } from "./StdProposalIsEntity"
import { StdProposalTariffItemTierEntity } from "./StdProposalTariffItemTierEntity"

export interface StandardProposalItemEntity {

    tempKey?: string | null,
    key?: string,
    priority?: number | null,
    seq?: number | null,
    proposalId?: number | null,
    schemeId?: number | null,
    priorityList?: number[] | null,
    tariffType: string,
    tariffCode?: string | null,
    forwarderCode?: string | null,
    master?: Date | null,
    tariffCodeDesc?: Date | null,
    tierList?: StdProposalTariffItemTierEntity[] | null,
    stdIsList?: StdProposalIsEntity[] | null,
    deleteInd?: string | null,
    dummyKey: string | null,

    [key: string]: string | boolean | number | Date | null | undefined | Object
}

export const EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY: StandardProposalItemEntity = {

    tempKey: null,
    key: "",
    priority: null,
    seq: null,
    proposalId: null,
    tariffType: "",
    tariffCode: "",
    forwarderCode: "",
    master: null,
    tariffCodeDesc: null,
    tierList: [],
    stdIsList: [],
    schemeId: null,
    priorityList: [],
    deleteInd: null,
    dummyKey: null,
}