import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { StandardProposalHeaderFormPanel } from "./Left/StandardProposalHeaderFormPanel";
import AddTariffItemRightPanel from "./Right/AddTariffItemRightPanel";
import StandardProposalDetailTitleBar from "./StandardProposalDetailTitleBar";

const StandardProposalDetailMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalDtlVM = useStandardProposalDetailVM();
    const { dynamicOptions, tariffNatureMap } = standardProposalState;


    const initialScreen = useCallback(async () => {
        setIsLoading(true);
        try {
            const results = await Promise.allSettled([
                standardProposalDtlVM.onPageInit(dynamicOptions, tariffNatureMap),
                // standardProposalDtlVM.loadDropdownOption(),
            ]);
            results?.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                } else if (index === 1 && result.status === 'rejected') {

                }
            });
            setIsLoading(false);
        } catch (error) {
        }
    }, [dynamicOptions, standardProposalDtlVM, tariffNatureMap]);

    useEffect(() => {
        initialScreen();
    }, [initialScreen])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className="main-comp-wrapper">
            <StandardProposalDetailTitleBar />
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"25%"}
                rightSectionWidth={"75%"}
                leftChildren={<StandardProposalHeaderFormPanel />}
                rightChildren={<AddTariffItemRightPanel />}
            />
        </div>
    </>
}

export default memo(StandardProposalDetailMaintenance);