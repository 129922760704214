import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { StandardProposalConstant } from "./StandardProposalConstant";
import { DECIMAL_2, INTEGER } from "presentation/utils/numberUtil";

const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF: any[] = [
    // {
    //     headerName: STANDARD_PROPOSAL_CONSTANT.TIER_NO,
    //     field: 'tierNo',
    //     enableRowGroup: false,
    //     enablePivot: true,
    //     enableValue: false,
    //     rowGroup: false,
    //     // pinned: "left",
    //     checkboxSelection: true,
    //     headerCheckboxSelection: true,
    //     filter: true,
    //     width: 150,
    // },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.CYCLE,
        field: 'cycle',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 150,
        dataType: INTEGER,
        cellStyle: { textAlign: 'right' },
    },
    // {
    //     headerName: STANDARD_PROPOSAL_CONSTANT.CYCLE,
    //     field: 'cycle',
    //     enableRowGroup: true,
    //     enablePivot: true,
    //     enableValue: true,
    //     filter: true,
    //     width: 100,
    // },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.FOR_EVERY,
        field: 'forevery',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: INTEGER,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.UOM,
        field: 'uom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.RATE,
        field: 'rate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.MINIMUM,
        field: 'minimum',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.SURCHARGE,
        field: 'surcharge',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.SURCHARGE_TYPE,
        field: 'surchargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_UOM,
        field: 'additionalUom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_RATE,
        field: 'additionalRate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}
