import { SelectionChangedEvent } from "ag-grid-community";
import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalDetailColumnDefinition";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalDetailTracked } from "presentation/store/StandardProposal/StandardProposalDetailProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";


const StandardProposalDetailTablePanel: React.FC = () => {
    const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const standardProposalDtlVM = useStandardProposalDetailVM();
    const { currentStandardProposal, currentSelectItemRows, isShowAddTariffItemPanel } = standardProposalDtlState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        standardProposalDtlVM.updateSelectedRows(selectedRows);
    }, [standardProposalDtlVM])

    const handleAdd = useCallback(() => {
        if (!currentStandardProposal.companyCode) {
            messageBarVM.showError("Company Code is mandatory.");
            return;
        }
        if (!currentStandardProposal.effectiveDate) {
            messageBarVM.showError("Effective Date is mandatory.");
            return;
        }
        if (!currentStandardProposal.expiryDate) {
            messageBarVM.showError("Expiry Date is mandatory.");
            return;
        }
        if (new Date(currentStandardProposal.expiryDate) <= new Date(currentStandardProposal.effectiveDate)) {
            messageBarVM.showError("The Expiry Date must be greater than the Effective Date.");
            return;
        }
        if (!currentStandardProposal.currencyCode) {
            messageBarVM.showError("Currency is mandatory.");
            return;
        }
        if (!currentStandardProposal.proposalCreatedByMethod) {
            messageBarVM.showError("Proposal By is mandatory.");
            return;
        }
        if (!currentStandardProposal.tariffNatureList || currentStandardProposal.tariffNatureList?.length === 0) {
            messageBarVM.showError("Tariff Nature is mandatory.");
            return;
        }
        standardProposalDtlVM.onAddTariffItemClick();
    }, [currentStandardProposal.companyCode, currentStandardProposal.currencyCode, currentStandardProposal.effectiveDate, currentStandardProposal.expiryDate, currentStandardProposal.proposalCreatedByMethod, currentStandardProposal.tariffNatureList, messageBarVM, standardProposalDtlVM]);

    const handDelete = useCallback(() => {
        if (currentSelectItemRows.length === 0) {
            messageBarVM.showError("Please select a record.");
            return;
        }
        standardProposalDtlVM.onTariffItemDeleteClick(currentSelectItemRows);
    }, [messageBarVM, standardProposalDtlVM, currentSelectItemRows]);

    const isRowSelectable = useCallback((params: any) => {
        return !isShowAddTariffItemPanel;
    }, [isShowAddTariffItemPanel]);

    const memoStandardProposalDetailTablePanel = useMemo(() => {
        return (
            <NbisTable
                id='standard-proposal-detail-table'
                headerLabel={StandardProposalConstant.Title.STANDARD_PROPOSAL_LIST}
                isNewColumnSetting={true}
                columns={INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF.slice()}
                data={transferRowData(standardProposalDtlState.standardProposalItemList?.slice() ?? [])}
                headerActionButtons={[
                    {
                        id: 'onDeleteButton',
                        icon: 'Icon-trash',
                        title: 'Delete'
                    },
                ]}
                onDeleteButton={handDelete}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAdd}
                showDeleteButton={false}
                showReloadIcon={false}
                showUploadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 145px)"
                ref={gridRef}
                messageVM={messageBarVM}
                handleRowSelectable={isRowSelectable}
            />
        );
    }, [handDelete, handleAdd, handleSelectionChange, isRowSelectable, messageBarVM, standardProposalDtlState.standardProposalItemList])

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isShowAddTariffItemPanel, standardProposalDtlState.isAddIs, standardProposalDtlState.isAddTier])

    return <><TableWrapper>
        {memoStandardProposalDetailTablePanel}
    </TableWrapper>
    </>;
}

export default memo(StandardProposalDetailTablePanel);
